import React from 'react'
import { Box } from 'components/layout/box'
import { Text } from 'components/typography/text'
import * as styles from './SessionCheck.css'
import Loader from 'components/Loader/Loader'
import { Container } from 'components/layout/container'

interface Props {}

export const SessionCheck = ({}: Props) => {
	return (
		<Box className={styles.wrapper}>
			<Container style={{ margin: 0 }}>
				<Box
					className={styles.content}
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center">
					<Box display="flex" justifyContent="center" padding={4} paddingBottom={12}>
						<Text variant="h1" color="neutral.500" className={styles.mavbotsLogoText}>
							Mav<span>Bots</span>
						</Text>
					</Box>
					<Box padding={8}>
						<Loader />
						<Text variant="body1">Checking session...</Text>
					</Box>
				</Box>
			</Container>
		</Box>
	)
}
