import React, { ReactNode, useState } from 'react'
import * as RadixTooltip from '@radix-ui/react-tooltip'
import { Box } from 'components/layout/box'
import * as styles from './Tooltip.css'

type ContentChild = {
	text: string
}

interface TooltipProps {
	children: ReactNode
	content?: string | ContentChild[] | ReactNode
	side?: 'top' | 'right' | 'bottom' | 'left'
}

const renderContent = (content: string | ContentChild[] | ReactNode) => {
	if (Array.isArray(content)) {
		return content.map((item, index) => (
			<React.Fragment key={index}>
				<Box dangerouslySetInnerHTML={{ __html: item.text }} />
				<br />
			</React.Fragment>
		))
	}

	if (typeof content === 'string') {
		return <Box dangerouslySetInnerHTML={{ __html: content }} />
	}

	if (React.isValidElement(content)) {
		return content
	}

	return null
}

export const Tooltip = ({ children, content, side = 'bottom' }: TooltipProps) => {
	const [open, setOpen] = useState(false)

	const handleClick = () => {
		setOpen(!open)
	}

	return (
		<RadixTooltip.Provider delayDuration={100}>
			<RadixTooltip.Root open={open} onOpenChange={setOpen}>
				<RadixTooltip.Trigger asChild onClick={handleClick}>
					{children}
				</RadixTooltip.Trigger>
				<RadixTooltip.Portal>
					<RadixTooltip.Content className={styles.tooltipContent} side={side} sideOffset={5}>
						{renderContent(content)}
						<RadixTooltip.Arrow className={styles.tooltipArrow} />
					</RadixTooltip.Content>
				</RadixTooltip.Portal>
			</RadixTooltip.Root>
		</RadixTooltip.Provider>
	)
}
