import React from 'react'
import clsx from 'clsx'
import * as styles from './VisuallyHidden.css'

interface VisuallyHiddenProps {
	children: React.ReactNode
	className?: string
}

export const VisuallyHidden = ({ children, className }: VisuallyHiddenProps) => {
	return <span className={clsx(styles.visuallyHidden, className)}>{children}</span>
}
