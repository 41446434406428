import React, { useMemo, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useOktaAuth } from '@okta/okta-react'

import { Box } from 'components/layout/box'
import * as styles from './MeetBots.css'
import { Card } from 'components/Card'
import { MeetAvatar } from '../components/Avatar/Avatar'
import { useArenaContext } from 'context/ArenaContext'
import { BotFilter } from '../components/BotFilters/BotFilter'
import { Text } from 'components/typography/text'
import { fetchBotStats, Trader } from 'api/mavbots-arena/fetchBotStats'
import { useQuery } from '@tanstack/react-query'
import { useMainAppContext } from 'context/MainAppContext'

const renderBots = (bots: Trader[]) => {
	const { arenaSelectedBot, setArenaSelectedBot } = useArenaContext()
	return bots.map(bot => (
		<MeetAvatar
			handleClick={() => setArenaSelectedBot(bot)}
			key={bot.bot_id}
			avatar={bot.avatar}
			botName={bot.bot_name}
			isSelected={arenaSelectedBot?.bot_id === bot.bot_id}
			role={bot.role}
			group={bot.group}
			isAvailable={bot.trades.length > 0}
		/>
	))
}

interface FormValues {
	selectedStrategies: string[]
	selectedTickers: string[]
	selectedBotIds: number[]
}

interface MeetBotsProps {}

export const MeetBots = ({}: MeetBotsProps) => {
	const { arenaBotsData } = useArenaContext()
	const { oktaAuth } = useOktaAuth()
	const { userGroup } = useMainAppContext()
	const accessToken = oktaAuth.getAccessToken()
	const [didInitialReset, setDidInitialReset] = useState(false)

	// Check if user has access to this bot's details based on their group
	const hasAccess = (botGroup?: string) => {
		if (userGroup === process.env.REACT_APP_CONQUER) return true
		if (userGroup === process.env.REACT_APP_INVEST) return botGroup !== 'conquer'
		if (userGroup === process.env.REACT_APP_DABBLE) return botGroup === 'dabble'
		return false
	}

	const strategies = useMemo(() => {
		const strategySet = new Set<string>()
		arenaBotsData.forEach(bot => {
			if (bot.strategy) {
				strategySet.add(bot.strategy)
			}
		})
		return Array.from(strategySet).map(strategy => ({
			label: strategy,
			value: strategy,
			isAvailable: true
		}))
	}, [arenaBotsData])

	const tickers = useMemo(() => {
		const tickerSet = new Set<string>()
		arenaBotsData.forEach(bot => {
			if (bot.trades && bot.trades.length > 0) {
				bot.trades.forEach(trade => {
					tickerSet.add(trade)
				})
			}
		})
		return Array.from(tickerSet).map(ticker => ({
			label: ticker,
			value: ticker,
			isAvailable: true
		}))
	}, [arenaBotsData])

	const botOptions = useMemo(() => {
		// Separate portfolio and trader bots
		const portfolioBots = arenaBotsData
			.filter(bot => bot.role === 'portfolio')
			.map(bot => ({
				label: bot.bot_name,
				value: bot.bot_id,
				avatar: bot.avatar,
				isAvailable: hasAccess(bot.group),
				role: 'portfolio' as const,
				isSeparator: false
			}))
			.sort((a, b) => {
				// Sort by availability first
				if (a.isAvailable && !b.isAvailable) return -1
				if (!a.isAvailable && b.isAvailable) return 1
				// Then by name
				return a.label.localeCompare(b.label)
			})

		const traderBots = arenaBotsData
			.filter(bot => bot.role === 'trader')
			.map(bot => ({
				label: bot.bot_name,
				value: bot.bot_id,
				avatar: bot.avatar,
				isAvailable: hasAccess(bot.group),
				role: 'trader' as const,
				isSeparator: false
			}))
			.sort((a, b) => {
				// Sort by availability first
				if (a.isAvailable && !b.isAvailable) return -1
				if (!a.isAvailable && b.isAvailable) return 1
				// Then by name
				return a.label.localeCompare(b.label)
			})

		// Add a separator between portfolio and trader bots
		const separator = {
			label: '──────────',
			value: -1, // Using -1 as a special value for separator
			avatar: '',
			isAvailable: false,
			isSeparator: true
		}

		// Combine them with portfolio bots first, then separator, then trader bots
		return [...portfolioBots, separator, ...traderBots]
	}, [arenaBotsData, hasAccess])

	// Set up form methods with empty default values
	const methods = useForm<FormValues>({
		defaultValues: {
			selectedStrategies: [],
			selectedTickers: [],
			selectedBotIds: []
		}
	})

	const { watch, setValue } = methods
	const selectedStrategies = watch('selectedStrategies') || []
	const selectedTickers = watch('selectedTickers') || []
	const selectedBotIds = watch('selectedBotIds') || []

	// Handle initial selection of all options
	// useEffect(() => {
	// 	if (!didInitialReset && strategies.length > 0 && tickers.length > 0 && botOptions.length > 0) {
	// 		// Select all options by default on initial load
	// 		console.log('test2')
	// 		setValue(
	// 			'selectedStrategies',
	// 			strategies.map(s => s.value)
	// 		)
	// 		setValue(
	// 			'selectedTickers',
	// 			tickers.map(t => t.value)
	// 		)
	// 		setValue(
	// 			'selectedBotIds',
	// 			botOptions.filter(option => !option.isSeparator).map(option => option.value as number)
	// 		)
	// 		setDidInitialReset(true)
	// 	}

	// 	console.log('getValue', getValues('selectedBotIds'))
	// }, [strategies, tickers, botOptions, didInitialReset, setValue])


	useEffect(() => {
		if (!didInitialReset && arenaBotsData.length > 0) {
			console.log('test2')
			setValue(
				'selectedStrategies',
				strategies.map(s => s.value)
			)
			setValue(
				'selectedTickers',
				tickers.map(t => t.value)
			)
			setValue(
				'selectedBotIds',
				botOptions.filter(option => !option.isSeparator).map(option => option.value as number)
			)
			setDidInitialReset(true)
		}

	}, [arenaBotsData, strategies, tickers, botOptions, didInitialReset, setValue])


	// Split, sort, and filter bots
	const groupedBots = useMemo(() => {
		// 1) Filter first
		const filteredBots = arenaBotsData.filter(bot => {
			// If user has NO strategies selected, show all bots
			// if (selectedStrategies.length === 0) return true
			// Otherwise, if user did select some, enforce those:
			if (!selectedStrategies.includes(bot.strategy)) return false

			// If user has NO bot IDs selected, show all bots
			// if (selectedBotIds.length === 0) return true
			// Otherwise check if this bot is selected
			if (!selectedBotIds.includes(bot.bot_id)) return false

			// For tickers:
			// If bot has no trades, show it anyway
			if (!bot.trades || bot.trades.length === 0) return true
			// If user has NO tickers selected, show all trading bots
			// if (selectedTickers.length === 0) return true
			// If user selected tickers, check if any match
			return bot.trades.some(t => selectedTickers.includes(t))
		})

		// 2) Sort your filtered bots
		const sortedAndGroupedBots = (bots: Trader[]) => {
			return bots
				.sort((a, b) => {
					// Sort by risk_adjusted_return first
					const riskAdjustedA = a.bot_details.widgets.risk_adjusted_return.text_1
					const riskAdjustedB = b.bot_details.widgets.risk_adjusted_return.text_1
					if (riskAdjustedA > riskAdjustedB) return -1
					if (riskAdjustedA < riskAdjustedB) return 1
					return 0
				})
				.sort((a, b) => {
					// Then push bots with no returns to the end
					const hasReturnsA = a.bot_details.cumulative_returns.length > 0
					const hasReturnsB = b.bot_details.cumulative_returns.length > 0
					if (hasReturnsA && !hasReturnsB) return -1
					if (!hasReturnsA && hasReturnsB) return 1
					return 0
				})
		}

		// 3) Split them by role (portfolio vs. trader)
		const portfolioBots = sortedAndGroupedBots(filteredBots.filter(bot => bot.role === 'portfolio'))
		const traderBots = sortedAndGroupedBots(filteredBots.filter(bot => bot.role === 'trader'))

		return { portfolio: portfolioBots, trader: traderBots }
	}, [arenaBotsData, selectedStrategies, selectedTickers, selectedBotIds])

	const { isLoading } = useQuery({
		queryKey: ['mavbots-arena', 'mavbots'],
		queryFn: () => fetchBotStats(accessToken),
		staleTime: 24 * 60 * 60 * 1000 // 24 hours
	})

	return (
		<FormProvider {...methods}>
			<Box className={styles.meetBotsContainer}>
				<Card
					title="Meet the bots"
					headerChildren={
						<Box display="flex" gap={2}>
							<BotFilter<'selectedBotIds'> title="Bots" options={botOptions} field="selectedBotIds" showRoleColors />
							<BotFilter<'selectedStrategies'> title="Strategy" options={strategies} field="selectedStrategies" />
							<BotFilter<'selectedTickers'> title="Ticker" options={tickers} field="selectedTickers" />
						</Box>
					}
					isLoading={isLoading}
					style={{ minHeight: 'auto' }}
					bodyStyle={{
						marginLeft: '-12px',
						marginRight: '-12px',
						paddingLeft: '24px',
						paddingRight: '24px'
					}}>
					<Box className={styles.botsWrapper}>
						{groupedBots.portfolio.length > 0 && renderBots(groupedBots.portfolio)}
						{groupedBots.portfolio.length !== 0 && groupedBots.trader.length !== 0 && (
							<Box className={styles.divider}></Box>
						)}
						{groupedBots.trader.length > 0 && renderBots(groupedBots.trader)}
						{isLoading && (
							<Box display="flex" justifyContent="center" alignItems="center" style={{ width: '100%' }}>
								<Text variant="body1" color="neutral.500" textAlign="center">
									Loading...
								</Text>
							</Box>
						)}
						{groupedBots.portfolio.length === 0 && groupedBots.trader.length === 0 && !isLoading && (
							<Box display="flex" justifyContent="center" alignItems="center" style={{ width: '100%' }}>
								<Text variant="body1" color="neutral.500" textAlign="center">
									No Results
								</Text>
							</Box>
						)}
					</Box>
				</Card>
			</Box>
		</FormProvider>
	)
}
