import React from 'react'

import { Box } from 'components/layout/box'
import * as styles from './BotProfileTabs.css'
import { TabsProvider } from 'components/Tabs/TabsProvider'
import { Tabs } from 'components/Tabs'
import { useArenaContext } from 'context/ArenaContext'
import { SimpleTabsTable } from './components/SimpleTabsTable'
import { AdvancedTabsTable } from './components/AdvancedTabsTable'

export const BotProfileTabs = () => {
	const { arenaSelectedBot } = useArenaContext()
	return (
		<Box>
			<TabsProvider defaultTab="TotalStats" tabs={['5YStats', 'TotalStats', 'RecentTrades']}>
				<Tabs>
					<Tabs.Tab value="5YStats">5Y Stats</Tabs.Tab>
					<Tabs.Tab value="TotalStats">Total Stats</Tabs.Tab>
					<Tabs.Tab value="RecentTrades">Recent Trades</Tabs.Tab>

					<Tabs.Panel value="5YStats">
						<Box className={styles.tabsLayout}>
							<SimpleTabsTable data={arenaSelectedBot?.bot_details.tabs.tab_1} />
						</Box>
					</Tabs.Panel>
					<Tabs.Panel value="TotalStats">
						<Box className={styles.tabsLayout}>
							<SimpleTabsTable data={arenaSelectedBot?.bot_details.tabs.tab_2} />
						</Box>
					</Tabs.Panel>
					<Tabs.Panel value="RecentTrades">
						<Box className={styles.tabsLayout}>
							<AdvancedTabsTable />
						</Box>
					</Tabs.Panel>
				</Tabs>
			</TabsProvider>
		</Box>
	)
}
