import React, { useEffect, useState, useRef } from 'react'

import { Box } from 'components/layout/box'
import * as styles from './Avatar.css'
import { Text } from 'components/typography/text'
import clsx from 'clsx'
import { useOktaAuth } from '@okta/okta-react'
import { useArenaContext } from 'context/ArenaContext'
import { redirectToCustomerPortal } from 'helpers/redirectToCustomerPortal'

interface AvatarProps {
	avatar?: string
	handleClick?: () => void
}

export const Avatar = ({ avatar, handleClick }: AvatarProps) => {
	return (
		<Box className={styles.avatar}>
			<Box className={styles.avatarBackground} />
			<Box className={styles.imgWrapper}>
				<img src={avatar} className={styles.img} />
			</Box>
			<Box className={styles.seeStatsLayer} onClick={handleClick}>
				See Stats
			</Box>
		</Box>
	)
}

interface ProfileAvatarProps {
	videoAvatar: string
	fallbackImg?: string
	handleClick?: () => void
	shouldHover?: boolean
	style?: React.CSSProperties
	role?: 'portfolio' | 'trader'
}

export const ProfileAvatar = ({
	videoAvatar,
	fallbackImg,
	handleClick,
	shouldHover,
	style,
	role = 'trader'
}: ProfileAvatarProps) => {
	const [isInView, setIsInView] = useState(false) // Track if component is in viewport
	const [videoLoaded, setVideoLoaded] = useState(false) // Track if video has finished loading
	const [videoSrc, setVideoSrc] = useState<string | undefined>()
	const avatarRef = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		// If there's no videoAvatar or we're not in view, do nothing yet
		if (!videoAvatar || !isInView) return

		const localUrl = `/arena/${videoAvatar.toLowerCase()}`
		setVideoSrc(localUrl)
	}, [videoAvatar, isInView])

	// IntersectionObserver to check if avatar is in the viewport:
	useEffect(() => {
		const observerCallback = (entries: IntersectionObserverEntry[]) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					setIsInView(true)
					// Once in view, we can unobserve (so it doesn't keep firing)
					if (avatarRef.current) observer.unobserve(avatarRef.current)
				}
			})
		}

		const observerOptions = {
			root: null, // viewport
			threshold: 0.1 // load video when 10% visible
		}

		const observer = new IntersectionObserver(observerCallback, observerOptions)
		if (avatarRef.current) observer.observe(avatarRef.current)

		return () => {
			// Cleanup
			observer.disconnect()
		}
	}, [])

	// Determine styles based on role and shouldHover
	const avatarStyle = shouldHover
		? role === 'portfolio'
			? styles.profileAvatarPortfolioHover
			: styles.profileAvatarTraderHover
		: role === 'portfolio'
		? styles.profileAvatarPortfolio
		: styles.profileAvatarTrader

	const avatarBackgroundStyle =
		role === 'portfolio' ? styles.profileAvatarBackgroundPortfolio : styles.profileAvatarBackgroundTrader

	return (
		<div ref={avatarRef} style={style} className={clsx(avatarStyle)}>
			<Box className={avatarBackgroundStyle} />
			<Box className={clsx(shouldHover ? styles.imgWrapper : styles.profileWrapper)}>
				{videoSrc && (
					<video
						src={videoSrc}
						className={styles.img}
						poster={`/arena/${fallbackImg?.toLowerCase()}`}
						onLoadedData={() => setVideoLoaded(true)}
						autoPlay
						loop
						muted
						playsInline
					/>
				)}
			</Box>
			{/* Only show 'See Stats' when shouldHover is true */}
			{shouldHover && (
				<Box
					className={styles.seeStatsLayer}
					onClick={handleClick}
					style={{ color: role === 'portfolio' ? '#F68C0E' : '#67D586' }}>
					See Stats
				</Box>
			)}
		</div>
	)
}

interface MeetAvatarProps {
	avatar: string
	botName: string
	isSelected?: boolean
	handleClick?: () => void
	isAvailable: boolean
	role?: 'portfolio' | 'trader'
	group?: 'conquer' | 'invest' | 'dabble'
}

export const MeetAvatar = ({
	avatar,
	botName,
	isSelected,
	handleClick,
	isAvailable,
	role = 'trader',
	group
}: MeetAvatarProps) => {
	const { oktaAuth } = useOktaAuth()

	const handleCustomerPortalRedirect = () => {
		redirectToCustomerPortal(() => oktaAuth.getAccessToken())
	}

	// Decide if we're dealing with trader or portfolio
	const avatarStyle = role === 'portfolio' ? styles.avatarPortfolio : styles.avatarTrader
	const avatarBackgroundStyle = role === 'portfolio' ? styles.avatarBackgroundPortfolio : styles.avatarBackgroundTrader
	const selectedAvatarStyle =
		role === 'portfolio' ? styles.meetAvatarSelectedPortfolio : styles.meetAvatarSelectedTrader

	return (
		<Box onClick={handleClick} display="flex" flexDirection={'column'} gap={1.5}>
			<Box className={clsx(avatarStyle, isSelected && selectedAvatarStyle)}>
				<Box className={clsx(avatarBackgroundStyle, !isAvailable && styles.grayscale)} />
				<Box className={clsx(styles.imgWrapper, !isAvailable && styles.grayscale)}>
					<img src={`/arena/${avatar.toLowerCase()}`} className={styles.img} />
				</Box>

				{!isAvailable && (
					<>
						<Box className={styles.meetAvatarDisabledButtonWrapper}>
							<button className={styles.conquerButton} onClick={handleCustomerPortalRedirect}>
								{group}
							</button>
						</Box>
					</>
				)}
			</Box>

			<Text variant="body2Bold" color="neutral.50" textAlign="center">
				{botName}
			</Text>
		</Box>
	)
}
