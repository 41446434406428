import { useState } from 'react'
import { useSpring, animated } from 'react-spring'

import * as styles from './ChatButton.css'
import { Box } from 'components/layout/box'
import botImg from 'assets/chat-bot.jpg'
import { Text } from 'components/typography/text'

export const ChatButton = ({ ...props }) => {
	const [hovered, setHovered] = useState(false)

	const { blur, opacity } = useSpring({
		blur: hovered ? 0 : 6, // Unmuted on hover
		opacity: hovered ? 1 : 0.6, // Change opacity on hover
		config: { tension: 150, friction: 20 }
	})

	return (
		<Box className={styles.chatWrapper} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				width="80"
				height="80"
				viewBox="0 0 80 80"
				fill="none">
				<g filter={`url(#filter0_ii_1521_1430)`}>
					<animated.path
						style={{
							filter: `blur(${blur}px)`,
							opacity: opacity
						}}
						d="M26.3132 79.9032C35.8251 80.5546 45.5142 77.8065 56.1486 75.0381C74.2186 67.8686 82.122 49.9964 79.514 36C76.8554 27.315 76.4722 25.4791 72.5729 18.4225C68.7327 11.4202 63.3564 6.22945 55.735 2.70115C48.1137 -0.88143 38.2473 -0.827149 30.0943 2.42974C21.9412 5.63235 17.9086 11.4202 13.5958 18.4225C9.22384 25.4248 3.12826 35.6309 1.23769 44.4245C-0.652865 53.2724 -0.686427 59.3508 3.33102 67.3302C7.28939 75.3638 16.7422 79.3061 26.3132 79.9032Z"
						fill="url(#pattern0_1521_1430)"
						fillOpacity="1"
					/>
				</g>
				<path
					d="M26.3815 78.9055L26.3754 78.9051C16.9652 78.318 7.95665 74.4555 4.22808 66.8881L4.2242 66.8805C2.26587 62.9909 1.3131 59.6045 1.06634 56.1321C0.81854 52.6453 1.27849 49.0193 2.21562 44.6335C4.06368 36.0393 10.0564 25.9795 14.444 18.9521L14.4472 18.9469C18.749 11.9627 22.6357 6.43396 30.4599 3.36052L30.4653 3.35839C38.3937 0.191217 47.9692 0.155607 55.3096 3.60615L55.3096 3.60616L55.3149 3.60862C62.7375 7.0449 67.956 12.0836 71.6961 18.9034L71.6976 18.9062C75.192 25.2299 75.8065 27.2506 77.8843 34.083C78.0878 34.7523 78.3054 35.4679 78.5412 36.2382C81.0286 49.7455 73.3947 67.0888 55.8367 74.086C55.1977 74.2524 54.563 74.4184 53.9323 74.5834C44.0307 77.1732 35.12 79.5039 26.3815 78.9055Z"
					stroke="url(#paint0_linear_1521_1430)"
					strokeWidth="2"
				/>
				<defs>
					<filter
						id="filter0_ii_1521_1430"
						x="-10"
						y="-10"
						width="100"
						height="100"
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB">
						<feFlood floodOpacity="0" result="BackgroundImageFix" />
						<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dx="-10" dy="-10" />
						<feGaussianBlur stdDeviation="10" />
						<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
						<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
						<feBlend mode="hard-light" in2="shape" result="effect1_innerShadow_1521_1430" />
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dx="10" dy="10" />
						<feGaussianBlur stdDeviation="10" />
						<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
						<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
						<feBlend mode="hard-light" in2="effect1_innerShadow_1521_1430" result="effect2_innerShadow_1521_1430" />
					</filter>
					{/* img container */}
					<pattern id="pattern0_1521_1430" patternContentUnits="objectBoundingBox" width="1" height="1">
						<image xlinkHref={botImg} width="1" height="1" preserveAspectRatio="xMidYMid slice" />
					</pattern>
					{/* Border pattern */}
					<linearGradient
						id="paint0_linear_1521_1430"
						x1="11"
						y1="62"
						x2="61"
						y2="1.28483e-06"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#279445" />
						<stop offset="0.105" stopColor="#29392A" />
						<stop offset="0.556989" stopColor="#29392A" />
						<stop offset="1" stopColor="#67D586" />
					</linearGradient>
					<image id="botImg" width="1024" height="1024" xlinkHref={botImg} />
				</defs>
				{/* Second SVG starts here */}
				<g style={{ mixBlendMode: 'overlay' }}>
					<svg width="64" height="72" viewBox="0 0 61 70" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g>
							<animated.path
								style={{
									filter: `blur(${blur}px)`,
									opacity: opacity
								}}
								d="M1.02911 42.5C-0.819421 48.9129 -0.470907 63.5 7.25439 69.5006C-5.24561 53.5006 12.7544 18.7456 39.3592 15.6448C49.2579 14.491 57.966 11.2272 60.5291 6.99987C54.5291 1.99987 47.0214 0.0970498 40.7544 0.000614703C34.4423 -0.0476028 25.6376 2.62268 20.5291 8C11.0291 18 2.87765 36.0871 1.02911 42.5Z"
								fill="url(#paint0_radial_9511_938)"
							/>
						</g>
						<defs>
							<radialGradient
								id="paint0_radial_9511_938"
								cx="0"
								cy="0"
								r="1"
								gradientUnits="userSpaceOnUse"
								gradientTransform="translate(16.5689 14.559) rotate(75.9548) scale(56.6347 51.1383)">
								<stop stopColor="#D9D9D9" />
								<stop offset="1" stopColor="#737373" />
							</radialGradient>
						</defs>
					</svg>
				</g>
				{/* Second SVG ends here */}
			</svg>
			{hovered && (
				<Text variant="metadata" color="neutral.400" fontSize="xxsmall">
					Work in progress
				</Text>
			)}
		</Box>
	)
}
