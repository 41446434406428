import React from 'react'
import numeral from 'numeral'
import NumberFlow from '@number-flow/react'
import { AnimatePresence, motion } from 'motion/react'

import { Box } from 'components/layout/box'
import { Text } from 'components/typography/text'
import * as styles from './WidgetCard.css'
import { getColorForValue } from 'helpers/getColorForValue'
import { CircleChart } from './CircleChart'
import dayjs from 'dayjs'
import { WidgetCard } from 'api/mavbots-arena/fetchBotStats'
import { useMouseTracking } from 'hooks/useMouseTracking'

const renderSubValue = (value: number | string | undefined, numberStyle: 'percent' | 'number') => {
	if (value === undefined) {
		return <span>??</span>
	}
	const color = getColorForValue(value)
	const numericValue = numeral(value).value()
	if (numericValue === null || isNaN(numericValue)) {
		return <span>-</span> // Handle invalid numbers
	}

	const formattedValue =
		numericValue >= 0
			? `+${
					numberStyle === 'number'
						? Number.isInteger(value)
							? numeral(value).format('0,0')
							: numeral(value).format('0,0.00')
						: `${numeral(value).format('0,0.00')}%`
			  }`
			: numberStyle === 'number'
			? Number.isInteger(value)
				? numeral(value).format('0,0')
				: numeral(value).format('0,0.00')
			: `${numeral(value).format('0,0.00')}%`

	return <span style={{ color }}>{formattedValue}</span>
}

export const WidgetCardValue = ({ text_1, text_2, date }: WidgetCard) => {
	const text1Value = typeof text_1 === 'number' ? text_1 : parseFloat(text_1 as string)
	const { cardRef, background } = useMouseTracking()

	return (
		<div ref={cardRef} className={styles.widgetCard} style={{ background }}>
			<Text className={styles.widgetTitle}>Value</Text>
			<Box display="flex" flexDirection="column" gap={0.5}>
				<Text className={styles.valueText}>
					<NumberFlow
						style={{ lineHeight: 0.8 }}
						value={text1Value}
						locales="en-US"
						format={{ style: 'currency', currency: 'USD' }}
					/>
				</Text>
				<Text className={styles.subValueText}>{text_2}</Text>
			</Box>
			<Text className={styles.dateText}>Since {dayjs(date).format('MMM D YYYY')}</Text>
		</div>
	)
}

export const WidgetCardTotalReturn = ({ text_1, text_2, text_3, date }: WidgetCard) => {
	const text1Value = typeof text_1 === 'number' ? text_1 : parseFloat(text_1 as string)
	const { cardRef, background } = useMouseTracking()

	return (
		<div ref={cardRef} className={styles.widgetCard} style={{ background }}>
			<Text className={styles.widgetTitle}>Total Return</Text>
			<Box display="flex" flexDirection="column" gap={0.5}>
				<Text className={styles.valueText}>
					<NumberFlow
						style={{ lineHeight: 0.8 }}
						value={text1Value / 100}
						locales="en-US"
						format={{
							style: 'percent',
							minimumFractionDigits: 2,
							maximumFractionDigits: 2
						}}
						prefix={text1Value >= 0 ? '+' : ''}
					/>
				</Text>
				<Text className={styles.subValueText}>
					({renderSubValue(text_2, 'percent')} {text_3})
				</Text>
			</Box>
			<Text className={styles.dateText}>Since {dayjs(date).format('MMM D YYYY')}</Text>
		</div>
	)
}

export const WidgetCardRiskAdjustedReturn = ({ text_1, text_2, text_3, date }: WidgetCard) => {
	const text1Value = typeof text_1 === 'number' ? text_1 : parseFloat(text_1 as string)
	const { cardRef, background } = useMouseTracking()

	return (
		<div ref={cardRef} className={styles.widgetCard} style={{ background }}>
			<Text className={styles.widgetTitle}>Risk Adjusted Return</Text>
			<Box display="flex" flexDirection="column" gap={0.5}>
				<Text className={styles.valueText}>
					<NumberFlow
						style={{ lineHeight: 0.8 }}
						value={text1Value}
						locales="en-US"
						format={{
							minimumFractionDigits: 2,
							maximumFractionDigits: 2
						}}
					/>
				</Text>
				<Text className={styles.subValueText}>
					({renderSubValue(text_2, 'number')} {text_3})
				</Text>
			</Box>
			<Text className={styles.dateText}>Since {dayjs(date).format('MMM D YYYY')}</Text>
		</div>
	)
}

export const WidgetCardRiskRating = ({ text_1, date }: WidgetCard) => {
	const { cardRef, background } = useMouseTracking()

	return (
		<div ref={cardRef} className={styles.widgetCard} style={{ background }}>
			<Text className={styles.widgetTitle}>Risk Rating</Text>
			<Box display="flex" flexDirection="column" gap={0.5}>
				<AnimatePresence mode="wait" initial={false}>
					<motion.div
						key={`${text_1}-${date}`}
						initial={{ opacity: 0, y: -5 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: 5 }}
						transition={{ duration: 0.3 }}>
						<Text className={styles.valueText}>{text_1}</Text>
					</motion.div>
				</AnimatePresence>
			</Box>
			<Text className={styles.dateText}>Since {dayjs(date).format('MMM D YYYY')}</Text>
		</div>
	)
}
