import { useOktaAuth } from '@okta/okta-react'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'

import { fetchLeaderboard, LeaderboardResponseInterface } from 'api/mavbots-arena'
import { Box } from 'components/layout/box'
import { Text } from 'components/typography/text'
import { Leaderboard as LeaderboardTable } from 'components/Leaderboard'
import { Card } from 'components/Card'
import { useMainAppContext } from 'context/MainAppContext'

import * as styles from './Leaderboard.css'

export const Leaderboard = () => {
	const { oktaAuth } = useOktaAuth()
	const accessToken = oktaAuth.getAccessToken()
	const { userGroup, isSessionVerifiedAsCurrent } = useMainAppContext()
	const {
		data: rawLeaderboardData,
		isLoading,
		isError
	} = useQuery<LeaderboardResponseInterface>({
		queryKey: ['arena-leaderboard'],
		queryFn: () => fetchLeaderboard(accessToken),
		enabled: !!accessToken && !!userGroup && isSessionVerifiedAsCurrent,
		staleTime: 24 * 60 * 60 * 1000
	})

	return (
		<Box className={styles.mainContainer}>
			<Text className={styles.header} variant="body1Bold">
				Leaderboard
			</Text>
			<Card
				style={{
					height: 'inherit',
					padding: '2px 12px',
					marginLeft: '7px',
					marginRight: '7px',
					boxShadow: '-4px -4px 8px 0px rgba(255, 255, 255, 0.04), 4px 4px 8px 0px rgba(0, 0, 0, 0.12)',
					background: '#21242B'
				}}
				bodyStyle={{ borderRadius: '20px' }}>
				<LeaderboardTable />
			</Card>
			<p className={styles.lastUpdated}>
				{rawLeaderboardData?.result[0].lastModified
					? dayjs(rawLeaderboardData.result[0].lastModified).format('[Last update:] MMM D, YYYY [at] h:mm A')
					: 'N/A'}
			</p>
		</Box>
	)
}
