import React, { useState } from 'react'
import { motion, AnimatePresence } from 'motion/react'
import clsx from 'clsx'
import { useOrientation } from '@uidotdev/usehooks'
import { useOktaAuth } from '@okta/okta-react'
import dayjs from 'dayjs'

import { Box } from 'components/layout/box'
import * as styles from './BotProfile.css'
import { Card } from 'components/Card'
import { ProfileAvatar } from 'components/MavbotsArena/components/Avatar/Avatar'
import { Text } from 'components/typography/text'
// import { Badge } from 'components/MavbotsArena/components/Badge/Badge'
import { Trader } from 'components/MavbotsArena/data/data'
import { Button } from 'components/inputs/button'
// import { RiskChip } from 'components/MavbotsArena/components/RiskChip/RiskChip'
import { Stack } from 'components/layout/stack'
import { TradesList } from 'components/MavbotsArena/components/TradesList/TradesList'
import { BotProfileTabs } from './BotProfileTabs/BotProfileTabs'
import { BotProfileChart } from './BotProfileChart/BotProfileChart'
import { useArenaContext } from 'context/ArenaContext'
import { BotProfileWidgets } from './BotProfileWidgets/BotProfileWidgets'

import { useMainAppContext } from 'context/MainAppContext'
import { followBot, unfollowBot } from 'api/mavbots-arena'

import templateData from './templateCumulativeReturns.json'

// Use template data from JSON file
const templateCumulativeReturns = templateData.data

export const BotProfile = () => {
	const { arenaSelectedBot } = useArenaContext()
	const { type: orientationType } = useOrientation()
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<string | null>(null)
	const { userGroup } = useMainAppContext()

	const { feedNotificationList, setFeedNotificationList } = useMainAppContext()
	const { oktaAuth } = useOktaAuth()
	const token = oktaAuth.getAccessToken()

	const isFollowing = feedNotificationList?.includes(`arena_${arenaSelectedBot?.bot_name.toLowerCase()}`)

	// Check if user has access to this bot's details
	const hasAccess = (botGroup?: string) => {
		if (userGroup === process.env.REACT_APP_CONQUER) return true
		if (userGroup === process.env.REACT_APP_INVEST) return botGroup !== 'conquer'
		if (userGroup === process.env.REACT_APP_DABBLE) return botGroup === 'dabble'
		return false
	}

	const botHasAccess = arenaSelectedBot ? hasAccess(arenaSelectedBot.group) : false

	const handleToggleFollow = async () => {
		if (!token || !botHasAccess) return
		setIsLoading(true)
		setError(null)

		try {
			if (isFollowing) {
				const updatedList = await unfollowBot(token, `arena_${arenaSelectedBot?.bot_name.toLowerCase()}`)
				setFeedNotificationList(updatedList)
			} else {
				const updatedList = await followBot(token, `arena_${arenaSelectedBot?.bot_name.toLowerCase()}`)
				setFeedNotificationList(updatedList)
			}
		} catch (err: any) {
			console.error('Error toggling follow:', err)
			setError(err.message || 'An unexpected error occurred.')
		} finally {
			setIsLoading(false)
		}
	}

	const getUpgradeText = () => {
		if (!arenaSelectedBot) return ''
		if (arenaSelectedBot.group === 'conquer') return 'Upgrade to Conquer'
		return 'Upgrade to Invest'
	}

	return (
		<Box className={styles.botProfileContainer}>
			<Card style={{ width: '100%' }}>
				{!arenaSelectedBot ? (
					<Text variant="body2" textAlign="center" color="neutral.300">
						Loading...
					</Text>
				) : (
					<Box className={clsx(styles.profileWrapper, { [styles.unavailableBot]: !botHasAccess })}>
						{/* Profile */}
						<Box className={styles.profileGrid}>
							<Box className={styles.sideBotProfileDetails}>
								{/* AVATAR */}
								<Box className={styles.profileAvatarWrapper} display="flex" gap={{ mobile: 4, desktop: 2 }}>
									<ProfileAvatar
										style={{ width: '200px', height: '200px' }}
										videoAvatar={arenaSelectedBot.videoAvatar}
										// fallbackImg={arenaSelectedBot.avatar}
										role={arenaSelectedBot.role}
									/>
									<Box display="flex" gap={{ mobile: 4, desktop: 2 }} flexDirection="column">
										<Box className={styles.profileAvatarDetails}>
											<AnimatePresence mode="wait" initial={false}>
												<motion.div
													key={arenaSelectedBot.bot_name}
													initial={{ opacity: 0, y: 0 }}
													animate={{ opacity: 1, y: 0 }}
													exit={{ opacity: 0, y: 0 }}
													transition={{ duration: 0.1 }}>
													<Text variant="h2" fontWeight="medium">
														{arenaSelectedBot.bot_name}
													</Text>
												</motion.div>
											</AnimatePresence>
											{/* <Box display="flex" gap={0.5}>
												{arenaSelectedBot?.badges.map((badge, index) => (
													<Badge key={index} badge={badge} />
												))}
											</Box> */}
										</Box>

										<Button variant="primary" size="small" disabled={isLoading} onClick={handleToggleFollow}>
											{isFollowing ? 'Unfollow' : 'Follow'}
										</Button>
									</Box>
								</Box>
								{/* Profile Details */}

								<Box className={styles.botProfileDetails}>
									<AnimatePresence mode="wait" initial={true}>
										<motion.div
											key={arenaSelectedBot.bot_name}
											initial={{ opacity: 0, y: 0 }}
											animate={{ opacity: 1, y: 0 }}
											exit={{ opacity: 0, y: 0 }}
											transition={{ duration: 0.2 }}
											style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
											<TradesList
												trades={arenaSelectedBot?.trades}
												openPositions={arenaSelectedBot?.open_positions}
												group={arenaSelectedBot?.group}>
												<Text as="span" variant="body1" color="neutral.100">
													{arenaSelectedBot?.role === 'portfolio' ? 'Portfolio: ' : 'Trades: '}
												</Text>
											</TradesList>
											<Text variant="body1" color="neutral.100">
												{arenaSelectedBot.description}
											</Text>
										</motion.div>
									</AnimatePresence>
									{/* Strategy */}
									<Stack gap={2}>
										<AnimatePresence mode="wait" initial={true}>
											<motion.div
												key={arenaSelectedBot.bot_name}
												initial={{ opacity: 0, y: 0 }}
												animate={{ opacity: 1, y: 0 }}
												exit={{ opacity: 0, y: 0 }}
												transition={{ duration: 0.2 }}>
												<Text variant="body1Bold">
													Strategy:{' '}
													<Text as="span" color="primary.500" fontSize="small">
														{arenaSelectedBot?.strategy}
													</Text>
												</Text>
												<Text variant="body2">{arenaSelectedBot?.strategy_description}</Text>
											</motion.div>
										</AnimatePresence>
									</Stack>
								</Box>
							</Box>
							<Box className={styles.dataDetails}>
								<Box
									className={clsx({
										[styles.botProfileWidgetsWrapper]: orientationType === 'landscape-primary',
										[styles.botProfileWidgetsWrapperPortrait]: orientationType === 'portrait-primary'
									})}>
									<BotProfileWidgets />
								</Box>
								<Box className={styles.dataDetailsMain}>
									{/* PROFILE STRATEGY & RETURNS */}
									<Box className={styles.botProfileStrategyReturns}>
										{/* Returns */}
										<BotProfileChart
											data={arenaSelectedBot.bot_details.cumulative_returns ?? templateCumulativeReturns}
											watermarkText={!botHasAccess ? getUpgradeText() : undefined}
										/>
									</Box>
									<Box className={styles.botProfileTabsWrapper}>
										<BotProfileTabs />
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				)}
			</Card>
		</Box>
	)
}
