import { useEffect, useRef, useState } from 'react'

export const useMouseTracking = () => {
	const cardRef = useRef<HTMLDivElement>(null)
	const [background, setBackground] = useState(
		'radial-gradient(82% 100.15% at 0% 5%, rgba(217, 217, 217, 0.10) 0.01%, rgba(217, 217, 217, 0.10) 0.02%, rgba(217, 217, 217, 0.00) 100%)'
	)

	useEffect(() => {
		const card = cardRef.current
		if (!card) return

		const handleMouseMove = (e: MouseEvent) => {
			const rect = card.getBoundingClientRect()
			const x = ((e.clientX - rect.left) / rect.width) * 100
			const y = ((e.clientY - rect.top) / rect.height) * 100

			setBackground(
				`radial-gradient(circle at ${x}% ${y}%, rgba(217, 217, 217, 0.10) 0.01%, rgba(217, 217, 217, 0.10) 0.02%, rgba(217, 217, 217, 0.00) 100%)`
			)
		}

		card.addEventListener('mousemove', handleMouseMove)
		return () => card.removeEventListener('mousemove', handleMouseMove)
	}, [])

	return { cardRef, background }
}
