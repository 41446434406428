import React, { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useForm, SubmitHandler } from 'react-hook-form'

import * as styles from '../SettingsDialog.css'
import { Text } from 'components/typography/text'
import { Box } from 'components/layout/box'
import { useMainAppContext } from 'context/MainAppContext'
import { Input } from 'components/inputs/input'

import { Button } from 'components/inputs/button'

import { updateOktaUser } from 'api/settings/updateOktaUser'
import { updateSettings } from 'api/settings/updateSettings'

import { useChatContext } from 'context/ChatContext'
import { Avatar } from 'stream-chat-react'
import { generateAvatarBackground } from 'helpers/generateAvatarBackground'

type UserProfileFormInputs = {
	firstName: string
	lastName: string
	email: string
	chatName: string
	chatAvatarBackground: string
}

export const UserProfileForm = () => {
	const { oktaAuth } = useOktaAuth()
	const { chatName, setChatName, chatAvatarBackground, setChatAvatarBackground, setChatNameUpdated } =
		useMainAppContext()
	const { chatClient } = useChatContext()
	const token = oktaAuth.getAccessToken()

	const [loading, setLoading] = useState(false)
	const [notification, setNotification] = useState('')

	const {
		register,
		handleSubmit,
		setValue,
		watch,
		formState: { errors }
	} = useForm<UserProfileFormInputs>({
		defaultValues: {
			chatName: chatName,
			chatAvatarBackground: chatClient?.user?.image || chatAvatarBackground
			// chatAvatarBackground: chatAvatarBackground
		}
	})

	const data = oktaAuth.token.getUserInfo()
	console.log('data', data)

	const handleAvatarColorChange = () => {
		const linearGradient = generateAvatarBackground()
		setValue('chatAvatarBackground', linearGradient)
		setChatAvatarBackground(linearGradient)
	}

	const onSubmit: SubmitHandler<Omit<UserProfileFormInputs, 'email'>> = async data => {
		setLoading(true)
		setNotification('') // Clear any previous notifications
		try {
			if (!token) return

			// Step 1: Update chat name and avatar on getstream
			if (chatClient && chatClient.userID) {
				try {
					console.log('Updating chat user:', chatClient.userID)
					const response = await chatClient.upsertUser({
						id: chatClient.userID,
						name: data.chatName,
						image: data.chatAvatarBackground
					})
					console.log('Chat user updated successfully!')
					console.log('response', response)

					// Check if the user exists in the response
					if (response.users && response.users[chatClient.userID]) {
						console.log('Chat user updated successfully!')
						setChatName(data.chatName)
						setChatNameUpdated(true) // Mark the update as successful
						console.log('Chat name updated to:', data.chatName)
					} else {
						console.error('User not found in response:', response)
						throw new Error('Failed to update chat user.')
					}

					console.log('Chat name updated to:', data.chatName)
				} catch (error) {
					console.error('Error updating chat user:', error)
					throw new Error('Failed to update chat user.')
				}
			}

			// Step 2: Update user information on Okta
			await updateSettings(token, {
				firstName: data.firstName,
				lastName: data.lastName,
				chatName: data.chatName,
				chatAvatarBackground: data.chatAvatarBackground
			})
			setNotification('User information updated successfully!')
		} catch (error) {
			console.error('Error updating user:', error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		const fetchUserInfo = async () => {
			try {
				const userInfo = await oktaAuth.getUser()
				console.log('userInfo', userInfo)
				setValue('firstName', userInfo.given_name || '')
				setValue('lastName', userInfo.family_name || '')
				setValue('email', userInfo.email || '')
			} catch (error) {
				console.error('Failed to fetch user info:', error)
			}
		}

		fetchUserInfo()
	}, [])

	console.log('chatClient', chatClient)

	const currentAvatarBackground = watch('chatAvatarBackground')

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={styles.userProfileWrapper}>
			{/* Avatar Wrapper */}
			<Box className={styles.avatarWrapper}>
				<Box className={styles.avatar}>
					<Box className={styles.avatarBackground} />
					<Box className={styles.avatarImgWrapper} style={{ background: currentAvatarBackground }}>
						<div className={styles.avatarLayer}>{(watch('chatName') || ' ')[0].toUpperCase()}</div>
					</Box>
				</Box>
			</Box>
			<Box className={styles.changeAvatarBackground} onClick={() => handleAvatarColorChange()}>
				Change Avatar
			</Box>
			<Box className={styles.inputGroup}>
				<Text as="label" variant="body2Bold" color="neutral.50">
					First Name
				</Text>
				<Input {...register('firstName', { required: 'First Name is required' })} />
				{errors.firstName && <Text>{errors.firstName.message}</Text>}
			</Box>
			<Box className={styles.inputGroup}>
				<Text as="label" variant="body2Bold" color="neutral.50">
					Last Name
				</Text>
				<Input {...register('lastName', { required: 'Last Name is required' })} />
				{errors.lastName && <Text>{errors.lastName.message}</Text>}
			</Box>
			<Box className={styles.inputGroup}>
				<Text as="label" variant="body2Bold" color="neutral.50">
					Email
				</Text>
				<Input disabled value={watch('email')} />
				{errors.email && <Text>{errors.email.message}</Text>}
			</Box>
			<Box className={styles.inputGroup}>
				<Text as="label" variant="body2Bold" color="neutral.50">
					Chat Nickname
				</Text>
				<Input {...register('chatName')} />
			</Box>
			<Box display="flex" style={{ marginLeft: 'auto' }}>
				<Button type="submit" disabled={loading}>
					{loading ? 'Saving...' : 'Save Changes'}
				</Button>
			</Box>
			{notification && (
				<Box style={{ marginTop: '1rem', color: notification.includes('success') ? 'green' : 'red' }}>
					{notification}
				</Box>
			)}
		</form>
	)
}
