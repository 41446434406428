import React, { useState, useRef, useEffect } from 'react'
import numeral from 'numeral'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'

import { Box } from 'components/layout/box'
import * as styles from './BotCard.css'

import { RiskChip } from 'components/MavbotsArena/components/RiskChip/RiskChip'
import { ProfileAvatar } from 'components/MavbotsArena/components/Avatar/Avatar'

import { Text } from 'components/typography/text'
import { Button } from 'components/inputs/button'
import { GraphIcon } from 'assets/icons/icons'
import { InlineIcon } from 'components/icons/inline-icon'
import { TradeNews } from './TradeNews'
import { TradesList } from 'components/MavbotsArena/components/TradesList/TradesList'
import { tokens } from 'style/theme.css'
import { useTabsContext } from 'components/Tabs/TabsProvider'
import { useArenaContext } from 'context/ArenaContext'
import { Trader } from 'api/mavbots-arena/fetchBotStats'
import dayjs from 'dayjs'
import { useMainAppContext } from 'context/MainAppContext'
import { followBot, unfollowBot } from 'api/mavbots-arena'
import { useOktaAuth } from '@okta/okta-react'

interface BotCardProps {
	data: Trader
}

export const BotCard = ({ data }: BotCardProps) => {
	const { onActiveTabChange } = useTabsContext()
	const { setArenaSelectedBot, arenaBotsTrades } = useArenaContext()
	const { feedNotificationList, setFeedNotificationList, userGroup } = useMainAppContext()
	const [isFlipped, setIsFlipped] = useState(false)
	const { oktaAuth } = useOktaAuth()
	const token = oktaAuth.getAccessToken()
	const queryClient = useQueryClient()

	// Check if user has access to this bot's details based on their group
	const hasAccess = () => {
		if (userGroup === process.env.REACT_APP_CONQUER) return true
		if (userGroup === process.env.REACT_APP_INVEST) return data.group !== 'conquer'
		if (userGroup === process.env.REACT_APP_DABBLE) return data.group === 'dabble'
		return false
	}

	// find arenaBotsTrades for this bot
	const trades = arenaBotsTrades.find(trades => trades.bot_id === data.bot_id)

	// Refs for cardFront and cardBack
	const cardFrontRef = useRef<HTMLDivElement | null>(null)
	const cardBackRef = useRef<HTMLDivElement | null>(null)
	const [cardHeight, setCardHeight] = useState(0)

	// Check if user is already following this bot
	const isFollowing = feedNotificationList?.includes(`arena_${data.bot_name.toLowerCase()}`)

	// Follow mutation with optimistic update
	const followMutation = useMutation({
		mutationFn: () => followBot(token!, `arena_${data.bot_name.toLowerCase()}`),
		onMutate: async () => {
			// Cancel any outgoing refetches
			await queryClient.cancelQueries({ queryKey: ['feed-notifications'] })

			// Snapshot the previous value
			const previousList = feedNotificationList || []

			// Optimistically update to the new value
			const newList = [...previousList, `arena_${data.bot_name.toLowerCase()}`]
			setFeedNotificationList(newList)

			// Return a context object with the snapshotted value
			return { previousList }
		},
		onError: (err, newTodo, context) => {
			// If the mutation fails, use the context returned from onMutate to roll back
			if (context?.previousList) {
				setFeedNotificationList(context.previousList)
			}
		},
		onSettled: () => {
			// Always refetch after error or success to ensure we have the latest data
			queryClient.invalidateQueries({ queryKey: ['feed-notifications'] })
		}
	})

	// Unfollow mutation with optimistic update
	const unfollowMutation = useMutation({
		mutationFn: () => unfollowBot(token!, `arena_${data.bot_name.toLowerCase()}`),
		onMutate: async () => {
			// Cancel any outgoing refetches
			await queryClient.cancelQueries({ queryKey: ['feed-notifications'] })

			// Snapshot the previous value
			const previousList = feedNotificationList || []

			// Optimistically update to the new value
			const newList = previousList.filter(item => item !== `arena_${data.bot_name.toLowerCase()}`)
			setFeedNotificationList(newList)

			// Return a context object with the snapshotted value
			return { previousList }
		},
		onError: (err, newTodo, context) => {
			// If the mutation fails, use the context returned from onMutate to roll back
			if (context?.previousList) {
				setFeedNotificationList(context.previousList)
			}
		},
		onSettled: () => {
			// Always refetch after error or success to ensure we have the latest data
			queryClient.invalidateQueries({ queryKey: ['feed-notifications'] })
		}
	})

	const handleToggleFollow = async () => {
		if (!token || !hasAccess()) return

		if (isFollowing) {
			unfollowMutation.mutate()
		} else {
			followMutation.mutate()
		}
	}

	// Set the height of cardBack based on cardFront
	useEffect(() => {
		if (cardFrontRef.current) {
			setCardHeight(cardFrontRef.current.offsetHeight)
		}
	}, [isFlipped])

	const handleFlipClick = () => {
		if (!hasAccess()) {
			// If user doesn't have access, don't allow flipping
			return
		}
		setIsFlipped(prev => !prev)
	}

	const handleAvatarClick = () => {
		onActiveTabChange('Roster')
		setArenaSelectedBot(data)
	}

	return (
		<Box className={styles.cardWrapper}>
			<Box className={`${styles.card} ${isFlipped ? styles.flippedCard : ''}`}>
				{/* Front of the card */}
				<div ref={cardFrontRef} className={`${styles.cardSide} ${styles.cardFront}`}>
					<Box display="flex" flexDirection="column" gap={4} flexGrow={1} justifyContent="space-between">
						<Box className={clsx(styles.mainCardGrid, !hasAccess() && styles.grayscale)}>
							{/* Avatar */}
							<Box className={styles.avatarWrapper}>
								<ProfileAvatar
									style={{ width: '88px', height: '88px' }}
									videoAvatar={data.videoAvatar}
									fallbackImg={data.avatar}
									role={data.role}
									shouldHover
									handleClick={handleAvatarClick}
								/>
								<RiskChip riskLevel={data.riskLevel} />
							</Box>
							{/* TOP PART */}
							<Box display="flex" flexDirection="column" gap={4}>
								<Box className={styles.headerWrapper}>
									<Box>
										<Text variant="body1Bold">{data.bot_name}</Text>
									</Box>

									<Button
										variant="primary"
										size="small"
										style={{ padding: '8px' }}
										onClick={handleToggleFollow}
										disabled={!hasAccess()}>
										{isFollowing ? 'Unfollow' : 'Follow'}
									</Button>
								</Box>

								{/* BOTTOM PART */}
								<Box className={styles.infoGrid}>
									{/* Details Mid */}
									<Box>
										<Box>
											<Text variant="body2Bold" color="neutral.100">
												<Text as="span" variant="body2" color="neutral.500">
													Strategy:{' '}
												</Text>
												{data.strategy}
											</Text>
										</Box>

										<TradesList trades={data.trades} openPositions={data.open_positions} group={data.group}>
											<Text as="span" variant="body2" color="neutral.500">
												{data.role === 'portfolio' ? 'Portfolio: ' : 'Trades: '}
											</Text>
										</TradesList>
										<Text variant="body2Bold" color="neutral.100" style={{ display: 'flex', gap: '4px' }}>
											<Text
												as="span"
												variant="body2"
												color="neutral.500"
												style={{ display: 'flex', alignItems: 'center' }}>
												<InlineIcon icon={GraphIcon} style={{ marginRight: '4px' }} />
												YTD Perf:
											</Text>
											<span
												style={{
													color:
														// @ts-ignore
														numeral(data.perf_ytd).value() > 0
															? tokens.colors['primary.500']
															: tokens.colors['warning.700']
												}}>
												{data.perf_ytd}
											</span>
										</Text>
									</Box>

									{/* Details Grid */}
									<Box className={styles.detailsGrid}>
										<Text variant="body2Bold" color="neutral.100">
											<Text as="span" variant="body2" color="neutral.500">
												5Y Perf:{' '}
											</Text>
											{data.perf_5y}
										</Text>

										<Text variant="body2Bold" color="neutral.100" textAlign={'right'}>
											<Text as="span" variant="body2" color="neutral.500">
												Avg Win:{' '}
											</Text>
											{data.win_avg}
										</Text>

										<Text variant="body2Bold" color="neutral.100">
											<Text as="span" variant="body2" color="neutral.500">
												Win Rate:{' '}
											</Text>
											{data.win_rate}
										</Text>

										<Text variant="body2Bold" color="neutral.100" textAlign={'right'}>
											<Text as="span" variant="body2" color="neutral.500">
												Avg Loss:{' '}
											</Text>
											{data.loss_avg}
										</Text>
									</Box>
								</Box>
							</Box>
						</Box>
						{trades && <TradeNews tradeHistory={trades.trade_history} />}
					</Box>
					<Box className={styles.showMoreButton} onClick={handleFlipClick}>
						{hasAccess() ? (
							'Show More'
						) : (
							<Text variant="body2" color="neutral.500">
								Upgrade to {data.group === 'conquer' ? 'Conquer' : 'Invest'} to view details
							</Text>
						)}
					</Box>
				</div>

				{/* Back of the card */}
				{hasAccess() && (
					<div
						ref={cardBackRef}
						className={`${styles.cardSide} ${styles.cardBack}`}
						style={{ height: `${cardHeight}px` }}>
						<Box className={styles.tradeHistoryContainer}>
							<Text variant="body1Bold" textAlign="center" color="primary.500">
								Recent trade history
							</Text>
							<Box as="ul" paddingLeft={4} className={styles.tradeHistoryList}>
								{trades &&
									trades.trade_history.map(trade => (
										<Box as="li" key={trade.id} className={styles.listItem}>
											<Text className={styles.dateTime} variant="body2">
												{dayjs(trade.datetime).format('MM/DD/YY h:mmA')}
											</Text>

											<Box className={`${styles.message}`}>
												<Text
													className={trade.message.includes('Bought') ? styles.greenMessage : styles.redMessage}
													variant="body2">
													{trade.message.split(' ')[0]}
												</Text>

												<Text className={styles.boldSymbol} variant="body2">
													{trade.message.split(' ')[1]}
												</Text>

												<Text variant="body2">{trade.message.split(' ').slice(2).join(' ')}</Text>
											</Box>

											<Text className={styles.size} variant="body2">
												{trade.size}
											</Text>
										</Box>
									))}
							</Box>
						</Box>

						<Box className={styles.flipButton} onClick={handleFlipClick}>
							Flip back
						</Box>
					</div>
				)}
			</Box>
		</Box>
	)
}
