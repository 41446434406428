import { useMemo, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { flexRender, useReactTable, getCoreRowModel } from '@tanstack/react-table'
import { useOktaAuth } from '@okta/okta-react'
import dayjs from 'dayjs'
import { motion, AnimatePresence } from 'motion/react'

import { Box } from 'components/layout/box'
import { leaderboardColumnsData } from './data'
import * as tableStyles from './Leaderboard.css'
import { TableInfoBox } from 'components/TableInfoBox'

import { fetchLeaderboard, LeaderboardResponseInterface } from 'api/mavbots-arena'
import { transformLeaderboardData } from './transformLeaderboardData'
import { useMainAppContext } from 'context/MainAppContext'

interface Props {
	isTransparent?: boolean
	handleClick?: (botId: number) => void
	isLastUpdatedVisible?: boolean
}

export const Leaderboard = ({ isTransparent, handleClick, isLastUpdatedVisible = false }: Props) => {
	const { oktaAuth } = useOktaAuth()
	const accessToken = oktaAuth.getAccessToken()
	const { didShuffle, setDidShuffle, userGroup, isSessionVerifiedAsCurrent } = useMainAppContext()

	const {
		data: rawLeaderboardData,
		isLoading,
		isError
	} = useQuery<LeaderboardResponseInterface>({
		queryKey: ['arena-leaderboard'],
		queryFn: () => fetchLeaderboard(accessToken),
		enabled: !!accessToken && !!userGroup && isSessionVerifiedAsCurrent,
		staleTime: 24 * 60 * 60 * 1000
	})

	const memoizedData = useMemo(() => {
		// Transform raw data to get rank & rank_change
		const transformed = transformLeaderboardData(rawLeaderboardData?.result ?? [])

		if (didShuffle) {
			// Sort by "newRank" ascending => rank=1 is top
			// (Remember each item has `rank` = newRank)
			return [...transformed].sort((a, b) => a.rank - b.rank)
		} else {
			// Sort by "oldRank" ascending => oldRank=1 is top
			// oldRank = newRank + (oldRank - newRank) = rank + rank_change
			return [...transformed].sort((a, b) => a.rank + a.rank_change - (b.rank + b.rank_change))
		}
	}, [rawLeaderboardData, didShuffle])

	const memoizedColumns = useMemo(() => leaderboardColumnsData, [leaderboardColumnsData])

	const table = useReactTable({
		data: memoizedData,
		columns: memoizedColumns,
		manualSorting: true,
		getCoreRowModel: getCoreRowModel()
	})

	useEffect(() => {
		if (didShuffle) {
			return
		}

		if (!isLoading && rawLeaderboardData?.result && rawLeaderboardData.result.length > 0) {
			const timer = setTimeout(() => {
				setDidShuffle(true)
			}, 5000)
			return () => clearTimeout(timer)
		}
	}, [didShuffle, isLoading, rawLeaderboardData, setDidShuffle])

	return (
		<Box display="flex" flexDirection="column" gap={4} overflow="auto">
			<Box display="flex">
				<div className={tableStyles.tableWrapper}>
					<table
						className={clsx(tableStyles.table, isTransparent && tableStyles.transparentTable)}
						style={{ overflowX: 'auto' }}>
						<thead className={tableStyles.tHead}>
							{table.getHeaderGroups().map(headerGroup => (
								<tr key={headerGroup.id}>
									{headerGroup.headers.map(header => {
										return (
											<th
												key={header.id}
												colSpan={header.colSpan}
												className={clsx(tableStyles.th, isTransparent && tableStyles.thWrap)}>
												{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
											</th>
										)
									})}
								</tr>
							))}
						</thead>
						<AnimatePresence>
							<motion.tbody className={tableStyles.tBody}>
								{isLoading && <TableInfoBox>Loading...</TableInfoBox>}
								{isError && <TableInfoBox>Error fetching data...</TableInfoBox>}
								{!isLoading && !isError && table.getRowModel().rows.length <= 0 && <TableInfoBox>No data</TableInfoBox>}
								{table.getRowModel().rows.map(row => (
									<motion.tr
										className={tableStyles.dataRow}
										layout // <--- crucial for re-order animation
										key={row.original.bot_id.toString()}
										transition={{ duration: 1.2, ease: 'easeInOut' }}>
										{row.getVisibleCells().map(cell => {
											// Check if the column ID is 'rank' (or any specific column)
											const isRankColumn = cell.column.id === 'rank'

											return (
												<td
													key={cell.id}
													className={tableStyles.td}
													// Add onClick only for the rank column
													onClick={isRankColumn && handleClick ? () => handleClick(row.original.bot_id) : undefined}>
													{flexRender(cell.column.columnDef.cell, cell.getContext())}
												</td>
											)
										})}
									</motion.tr>
								))}
							</motion.tbody>
						</AnimatePresence>
					</table>
					{isLastUpdatedVisible && (
						<p className={tableStyles.lastUpdated}>
							{rawLeaderboardData?.result[0].lastModified
								? dayjs(rawLeaderboardData.result[0].lastModified).format('[Last update:] MMM D, YYYY [at] h:mm A')
								: 'N/A'}
						</p>
					)}
				</div>
			</Box>
		</Box>
	)
}
