import "src/style/theme.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/style/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA3WWWW7bMBCG33sKAUaABKhTcri7T5Il3iOLszmxXWdzUvTunaEDlEOyEmzDH0aj2X6Si/12+9L9/tZ18/lzOKzXO7HoxM/sv8T/52D2q6ecAtESqkUnC6QRVXYGoRAnObKIdoecuPQkZ37RASdh0c0gXTm9QBoF3Tm9RDr0Q1yGnF4V+V4vOmd9TlYYCXhmdIPIaoZuMTZjGbqjMHS0ccjpPdJxGKdJ5fShGfK6GfIj0l7RndMnpMGH2E853SA1I9053SLVWveGJbqjYgaIitn+IipBA8tiv+j2t5cXp0p975T93mn8xTkRZ7nRc9NIG2b00jSy3NNr08hzo7emUeBG71TodOX0gHSKEbuS0w9K3fcyDoqV+pPaokPPfUiUzswPFgqMCppZNxpvGUYJzYTQ3hmGFb3TBa051tSxHqbAuiBRSjM1gvbc2lKSgNO0ZNjR4ECQRYAoq5kLVkYmIhnSK5UdOP4S1xg1w6SucRmXk2P4iko1RDfyReWaSmWn2PMAV1+l6tkEyps2RsnNpO2V7hkm2UnjBR9jeZ+srVE8bhKe1EYBt14fx8RPvLCPRyyXPO6nhCdfBLhJeOwdD3Cb8HLQvFQkv2h6kHxOSH9jcE5wvE9tMJPhAT7TKgBaCDbI8oVa7JURvPOvx3Rs5E7ejnHHkafznkTSx57P4CEtc8F47vsjOQk68Hp/NjGQdjAXq9krQTazBNIONljwNQlIO3qUo2T1Bt2UPJg2ts0tBVA7c9y1GPPF3gEh7WwMoVw2282KMdSK2B3SR1r8muOuRosBXZLX5eqfKejCFNiuANdp+TsVHd0/0PPJWXpO4ccefTD7VRn9TXurv23t9XBH1FX4vj4DwANt5bXfdetsAI9Ea7+oMCgZygtqB6guVTKUli4Z6qp6FkVlS4aK8iV7oT6X8JXOCSV8ozLJKqB3wqpO80Dc1rX6+A//bHZB0SHu3Ndc1t1R0OyOUq3uqHSeq+NQpm6PstSe2tS1uqZQSporR6GYbIFQTK5AqCVfINRMKBBqIxiOVvUpVNG5ThUMx18aYn/+Aus/j0EtCwAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/MavbotsArena/Arena/LatestTrades/BotCard/BotCard.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/MavbotsArena/Arena/LatestTrades/BotCard/BotCard.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VW226jMBB971dYlVZKpDqC3JrSl/2NfTTYgBNjI9u5ddV/37ENFBLSbKSVVmqA2sPMmcs5ZnbUu2W+iNDvJ4Rqpk3NMssPLEFxFEX16R2Wj5za0i/8cP9SbmpBzgnKBfP7272xPD/jTEnLpE1QBlem3VbJeFHa9t3Pp1kIF4dwhFIuC9hdh0Cp0pRprAnle5OgedQun7ApCVXHBOFlfQqXDfzAAOkiJZP5avWCvi7RLFpPX5AzuzKNYDv8zear6Uh+FTnhZmmxbCAM8wBIJNsVWu0lhayF0gk6ED3B2LyddrvaTAd1KjSnbsHdsWUVrFqGiWYEknzONZTt+XpfqyNsx7mvY0HqBEXuyWoiDbdcySQ850pXkMvKIPDHOhO3jI09C2hlrZlh+sDwwuOoVetAMwgF3e61Zu5b07kAG2UBzq9JvIkoK6Y904U39ahdMjAQLpW2PDnJGD5ww1MuuIUylJxSJkcKflncuyl2CJYewQfmkrITDMz7OPQG+DjWq3F2d0y5dkRwEKDB+0r2oq581PHUvmpLUgMv2tAR1bZPsNz6x87b+m7BH8A4QkZTu0akzB5ZgKgOTOdCHTH4I3urelhePRbvHKYb6Bb3X+jn+XjVNt/6/gLT6QLWYSw2joKdmzfvRnBjw3Rje65hxKWSrPdugtaO839Bw4DThOiObh0MInghMQdL05c0T8VGsCw7WXzUbkUqd+8BJR7oiDqIaVcvU2oud8N5SEfrNOJnOz4aHt9yULPsFpQ47vOZ3jKbD8xYAAjzhY8Nc1MlaM8i/zaeU1hdcIkDFy5GsPDvPtq0LvEBbD69lBMiBAjJ4kpIyvGoLZnGh2AkVitlObctA3tR+I3crpz11Oo657sz2sXbDuPdF49rJOXXsHbT2PnfPdQrh/tmq/LpxdE73zRHbzMsXkIvZkWM5/fN98gNTl8cSJ2IeAHJ9tq4Qa4Vb+0bTKmyVoFi402A6jlh+If/egpLIyTYTfsi5/OK5wO+iqR0uniLRds+G6v/VYN/lWz1QLIySA8XgAFUR+z1JJ5B8fs2amBTaHI2GRFsEqRHwWnoj+xo9npXHH5WjHKCTKbh6EREUjSpoO1NpV7XLrKPNvisvSrlYK730jD/3fF5N0DsOTCIEDURun6nQmW74O4WjFYrIOAfNbN7Ye4LAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var avatarWrapper = 'wrk4f3h';
export var blurValue = 'wrk4f3n';
export var boldSymbol = 'wrk4f3e';
export var card = 'wrk4f31';
export var cardBack = 'wrk4f36';
export var cardFront = 'wrk4f34';
export var cardSide = 'wrk4f33';
export var cardWrapper = 'wrk4f30';
export var dateTime = 'wrk4f3a';
export var detailsGrid = 'wrk4f3k';
export var flipButton = 'wrk4f3m';
export var flippedCard = 'wrk4f32';
export var grayscale = 'wrk4f3o';
export var greenMessage = 'wrk4f3c';
export var headerWrapper = 'wrk4f3i';
export var hiddenBack = 'wrk4f35';
export var infoGrid = 'wrk4f3j';
export var listItem = 'wrk4f39';
export var mainCardGrid = 'wrk4f3g';
export var message = 'wrk4f3b';
export var redMessage = 'wrk4f3d';
export var showMoreButton = 'wrk4f3l';
export var size = 'wrk4f3f';
export var tradeHistoryContainer = 'wrk4f37';
export var tradeHistoryList = 'wrk4f38';