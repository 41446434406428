import React, { useState, useEffect } from 'react'

import { BlockIcon } from 'components/icons/block-icon'
import { IconButton } from 'components/inputs/icon-button'
import { Box } from 'components/layout/box'

import { ReactComponent as EyeIcon } from './assets/eye-icon.svg'
import { ReactComponent as SearchIcon } from './assets/search-icon.svg'
import { ReactComponent as SettingsIcon } from './assets/settings-icon.svg'
// import { ReactComponent as LogoIcon } from './assets/logo.svg'
import { Text } from 'components/typography/text'

import { Link, useNavigate } from 'react-router-dom'

import * as styles from './Sidebar.css'
import { menuList } from './data'
import { NavItem, SidebarMain, SidebarMaster, SidebarNotch, TopReflection } from './components'
import { useOktaAuth } from '@okta/okta-react'
import { addNotification } from 'helpers/createFeedUser'
import { SettingsDialog } from 'components/Dialog/SettingsDialog'

export const DesktopSidebar = ({ isAllowed }: { isAllowed: boolean }) => {
	const [openNav, setOpenNav] = useState<string | null>(null)
	const [viewportHeight, setViewportHeight] = useState<number>(window.innerHeight)
	// const { userGroup, chatToken, feedUserToken, feedNotificationList, feedUserId } = useMainAppContext()
	const { oktaAuth } = useOktaAuth()
	const navigate = useNavigate()

	const handleAddNotification = async () => {
		const activity = {
			actor: 'herd_alex', // Replace with dynamic user data if needed
			verb: 'notify', // notify | invalidate
			object: 'Notification', // not important
			// time: new Date().toISOString(), // send time (now date)
			title: 'Hey im Alex', // Title of the Notification
			text: 'This is just to testing', // Text of the notification
			link: '/herd/herd-pulse#herd-pulse', // where to send the user when clicked
			actionKey: ['arena-quote'] // THIS IS ONLY when ver is "invalidate"
		}

		const result = await addNotification(activity)

		if (result) {
			// alert('Notification added successfully!')
			console.log('Notification Details:', result)
		} else {
			alert('Failed to add notification.')
		}
	}

	const handleToggle = (navId: string) => {
		setOpenNav(prevNav => (prevNav === navId ? null : navId))
	}

	useEffect(() => {
		const handleResize = () => {
			setViewportHeight(window.innerHeight)
		}

		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const filteredMenuList = menuList.filter(item => item.label !== 'More')

	return (
		<Box className={styles.desktopSidebar}>
			{/* MAIN */}
			<Box className={styles.mainReflection}>
				<SidebarMaster height={viewportHeight} />
			</Box>

			<Box className={styles.mainSidebar} paddingTop={6}>
				<Box className={styles.mainReflection}>
					<SidebarMain height={viewportHeight} />
				</Box>

				<Box className={styles.topReflection}>
					<TopReflection />
				</Box>

				{/* LOGO */}
				<Box paddingBottom={12} paddingLeft={8}>
					{/* <Link to="/">{LogoIcon && <LogoIcon width="100%" />}</Link> */}
					<Link to="/">
						<Text variant="h1" color="neutral.500" className={styles.mavbotsLogoText} style={{ display: 'flex', alignItems: 'baseline' }}>
							Mav<span>Bots</span><span className={styles.alpha}>alpha</span>
						</Text>
					</Link>
				</Box>

				{/* MENU */}
				<Box className={styles.menu}>
					{filteredMenuList.map(item => (
						<NavItem {...item} key={item.label} isOpen={openNav === item.label} onToggle={handleToggle} />
					))}
				</Box>
			</Box>

			{/* FOOTER */}
			<Box className={styles.footerSidebar}>
				<Box className={styles.notch}>
					<SidebarNotch />
				</Box>
				{/* <Box>
					<button onClick={handleAddNotification}>add notification</button>
					<Text>group: {userGroup}</Text>
					<Text>feedUserId: {feedUserId}</Text>
					<Text>chatToken: {chatToken}</Text>
					<Text>feedToken: {feedUserToken}</Text>
					<Text>feedNotificationList: {feedNotificationList}</Text>
				</Box> */}
				<Box className={styles.videoWrapper}>{/* <VideoPlayer videoId={'928460417'} /> */}</Box>
				{/* ACTION BUTTONS */}
				{/*  */}

				<Box className={styles.actionButtons} paddingBottom={8}>
					<IconButton variant="action" onClick={() => navigate('/watchlist')}>
						<BlockIcon icon={EyeIcon} size="medium" />
					</IconButton>
					<IconButton variant="action" onClick={() => navigate('/decoder/navigator')}>
						<BlockIcon icon={SearchIcon} size="medium" />
					</IconButton>
					<SettingsDialog>
						<IconButton variant="action">
							<BlockIcon icon={SettingsIcon} size="medium" />
						</IconButton>
					</SettingsDialog>
				</Box>
				{!isAllowed && <Box className={styles.isAllowed}>Checking user status...</Box>}
			</Box>
		</Box>
	)
}
