import { useState, useMemo, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useOktaAuth } from '@okta/okta-react'

import {
	flexRender,
	useReactTable,
	getCoreRowModel,
	VisibilityState,
	SortDirection,
	getSortedRowModel
} from '@tanstack/react-table'
import { economicColumnsData } from './data'
import * as tableStyles from './TableStyle.css'
import { TableInfoBox } from 'components/TableInfoBox'
import { SortIcon } from 'assets/icons/icons'
import { BlockIcon } from 'components/icons/block-icon'
import { Box } from 'components/layout/box'
import { fetchEconomic } from 'api/right-side-panel'

import { useMainAppContext } from 'context/MainAppContext'
interface Props {
	filter: { label: string; value: string }
}

export const Economic = ({ filter }: Props) => {
	const { oktaAuth } = useOktaAuth()
	const accessToken = oktaAuth.getAccessToken()
	const { userGroup, isSessionVerifiedAsCurrent } = useMainAppContext()
	const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
		date: false,
		time: true,
		country: true,
		importance: true,
		event: true,
		forecast: true,
		actual: true,
		previous: true
	})

	const {
		data: economicData,
		isLoading,
		isError
	} = useQuery({
		queryKey: ['calendar-economic', filter.value],
		queryFn: () => fetchEconomic(filter.value, accessToken),
		enabled: !!accessToken && !!userGroup && isSessionVerifiedAsCurrent,
		refetchInterval: 60000
	})

	// Memoize the data and columns
	const memoizedData = useMemo(() => economicData ?? [], [economicData])
	const memoizedColumns = useMemo(() => economicColumnsData, [economicColumnsData])

	const table = useReactTable({
		data: memoizedData,
		columns: memoizedColumns,
		debugTable: true,
		debugColumns: true,
		columnResizeMode: 'onChange',
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		initialState: {
			sorting: [{ id: 'importance', desc: true }]
		},
		state: {
			columnVisibility: columnVisibility
		},
		onColumnVisibilityChange: setColumnVisibility
	})

	useEffect(() => {
		const isToday = filter.value === 'today'
		setColumnVisibility({
			date: !isToday,
			time: isToday,
			country: true,
			importance: true,
			event: true,
			forecast: true,
			actual: true,
			previous: true
		})
	}, [filter])

	return (
		<Box display="flex" flexDirection="column" gap={4} overflow="auto">
			<Box display="flex">
				<div className={tableStyles.tableWrapper}>
					<table className={tableStyles.table} style={{ overflowX: 'auto' }}>
						<thead className={tableStyles.tHead}>
							{table.getHeaderGroups().map(headerGroup => (
								<tr key={headerGroup.id}>
									{headerGroup.headers.map(header => {
										return (
											<th key={header.id} colSpan={header.colSpan} className={tableStyles.th}>
												{header.isPlaceholder ? null : (
													<div
														style={{ cursor: header.column.getCanSort() ? 'pointer' : 'default' }}
														onClick={header.column.getToggleSortingHandler()}
														title={
															header.column.getCanSort()
																? header.column.getNextSortingOrder() === 'asc'
																	? 'Sort ascending'
																	: header.column.getNextSortingOrder() === 'desc'
																	? 'Sort descending'
																	: 'Clear sort'
																: undefined
														}>
														{flexRender(header.column.columnDef.header, header.getContext())}
														{(() => {
															const sortDirection = header.column.getIsSorted() as SortDirection
															return (
																<Box display="inline-block" style={{ paddingLeft: '4px' }}>
																	<BlockIcon
																		icon={SortIcon}
																		size={'small'}
																		color={
																			!sortDirection
																				? 'neutral.900.60'
																				: sortDirection === 'asc'
																				? 'primary.100'
																				: 'secondary.400'
																		}
																	/>
																</Box>
															)
														})()}
													</div>
												)}
											</th>
										)
									})}
								</tr>
							))}
						</thead>
						<tbody className={tableStyles.tBody}>
							{isLoading && <TableInfoBox>Loading...</TableInfoBox>}
							{isError && <TableInfoBox>Error fetching data...</TableInfoBox>}
							{!isLoading && !isError && table.getRowModel().rows.length <= 0 && (
								<TableInfoBox>No results found, please try again with different filters.</TableInfoBox>
							)}
							{table.getRowModel().rows.map(row => (
								<tr key={row.id} className={tableStyles.dataRow}>
									{row.getVisibleCells().map(cell => (
										<td key={cell.id} className={tableStyles.td}>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</Box>
		</Box>
	)
}
