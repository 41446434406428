import * as styles from './BotFilter.css'

import React, { useState } from 'react'
import * as Popover from '@radix-ui/react-popover'
import { Button } from 'components/inputs/button'
import { Box } from 'components/layout/box'
import { Text } from 'components/typography/text'
import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'

import { useArenaContext } from 'context/ArenaContext'
import { Search } from 'components/inputs/search'
import { tokens } from 'style/theme.css'
import { ChevronDownBoldIcon, SearchIcon } from 'assets/icons/icons'
import { useMainAppContext } from 'context/MainAppContext'
import { useWindowSize } from '@uidotdev/usehooks'
import { breakpoints } from 'style/tokens/breakpoints'
import { BlockIcon } from 'components/icons/block-icon'

interface Option<T> {
	label: string
	value: T
	avatar?: string
	isAvailable?: boolean
	role?: 'portfolio' | 'trader'
	isSeparator?: boolean
}

type FieldTypeMap = {
	selectedStrategies: string
	selectedTickers: string
	selectedBotIds: number
}

interface BotFilterProps<TField extends keyof FormValues> {
	options: Option<FieldTypeMap[TField]>[]
	title: string
	field: keyof FormValues
	hasDesktopSearch?: boolean
	showRoleColors?: boolean
}

interface FormValues {
	selectedStrategies: string[]
	selectedTickers: string[]
	selectedBotIds: number[]
}

export function BotFilter<TField extends keyof FormValues>({
	options,
	title,
	field,
	hasDesktopSearch,
	showRoleColors
}: BotFilterProps<TField>) {
	const { setValue, watch } = useFormContext<FormValues>()
	const [isOpen, setIsOpen] = useState(false)
	const [searchQuery, setSearchQuery] = useState('')
	const { userGroup } = useMainAppContext()

	const size = useWindowSize()
	const isTablet = size && size.width && size.width < Number(breakpoints.desktop)

	type OptionValueType = FieldTypeMap[TField]

	const selectedOptions = (watch(field) || []) as OptionValueType[]
	const { avatarImages } = useArenaContext()

	// Toggle dropdown visibility
	const handleIsOpen = () => {
		setIsOpen(!isOpen)
	}

	// Handle checkbox change for each option
	const handleCheckboxChange = (optionValue: OptionValueType) => {
		const newValues = selectedOptions.includes(optionValue)
			? selectedOptions.filter(s => s !== optionValue)
			: [...selectedOptions, optionValue]

		setValue(field, newValues as FormValues[TField])
	}

	// const handleClearAllChange = () => {
	// 	if (selectedOptions.length === options.length) {
	// 		// If all are selected, clear all
	// 		setValue(field, [] as FormValues[TField])
	// 	} else {
	// 		// Otherwise, select all
	// 		const allOptionValues = options.map(option => option.value)
	// 		setValue(field, allOptionValues as FormValues[TField])
	// 	}
	// }

	const handleClearAllChange = () => {
		const selectableOptions = options.filter(option => !option.isSeparator).map(option => option.value)

		if (selectedOptions.length === selectableOptions.length) {
			// If all are selected, clear all
			setValue(field, [] as FormValues[TField])
		} else {
			// Otherwise, select all valid options
			setValue(field, selectableOptions as FormValues[TField])
		}
	}

	// const isClearAllChecked = selectedOptions.length === options.length
	const selectableOptions = options.filter(option => !option.isSeparator) // Exclude separators
	const isClearAllChecked = selectedOptions.length === selectableOptions.length

	// Filter options based on search query
	const filteredOptions = options.filter(option => option.label.toLowerCase().includes(searchQuery.toLowerCase()))

	return (
		<Popover.Root open={isOpen} onOpenChange={handleIsOpen}>
			<Popover.Trigger asChild>
				<div style={{ position: 'relative' }}>
					<Button variant="primary" size="small">
						{title}
						<Box className={styles.iconWrapper}>
							<BlockIcon icon={ChevronDownBoldIcon} size="small" color="neutral.500" />
						</Box>
					</Button>
				</div>
			</Popover.Trigger>
			<Popover.Portal>
				<Popover.Content className={clsx('PopoverContent', styles.content)} sideOffset={5}>
					<Box>
						{!(isTablet && !hasDesktopSearch) && (
							<Search
								startIcon={<SearchIcon color={tokens.colors['neutral.500']} />}
								type="text"
								value={searchQuery}
								placeholder={`Search ${title.toLowerCase()}`}
								onChange={e => {
									setSearchQuery(e.target.value)
								}}
								onKeyDown={e => {
									if (e.key === 'Enter') {
										e.preventDefault()
									}
								}}
							/>
						)}

						<Box className={styles.dataListContainer}>
							<Box>
								<label className={styles.labelStyle}>
									<input
										type="checkbox"
										checked={isClearAllChecked}
										onChange={handleClearAllChange}
										className={styles.checkboxInput}
									/>
									<span className={styles.customCheckbox}></span>
									<Text variant="body2" color="primary.500">
										{isClearAllChecked ? 'Clear' : 'Select All'}
									</Text>
								</label>
							</Box>
							{filteredOptions.length > 0 ? (
								filteredOptions.map(option => {
									const imageSrc = option.avatar && avatarImages[option.avatar]
									let textColor = tokens.colors['neutral.600'] // default for unavailable

									if (option.isAvailable) {
										if (showRoleColors && option.role) {
											textColor =
												option.role === 'portfolio' ? tokens.colors['warning.500'] : tokens.colors['success.500']
										} else {
											textColor = tokens.colors['neutral.50']
										}
									}

									return (
										<Box key={String(option.value)}>
											{option.isSeparator ? (
												<Box className={styles.separator} />
											) : (
												<label className={styles.labelStyle}>
													<input
														type="checkbox"
														checked={selectedOptions.includes(option.value)}
														onChange={() => handleCheckboxChange(option.value)}
														className={clsx(styles.checkboxInput, {
															[styles.portfolioCheckbox]:
																showRoleColors && option.role === 'portfolio' && option.isAvailable,
															[styles.traderCheckbox]: showRoleColors && option.role === 'trader' && option.isAvailable,
															[styles.unavailableCheckbox]: !option.isAvailable
														})}
													/>
													<span
														className={clsx(styles.customCheckbox, {
															[styles.portfolioCheckbox]:
																showRoleColors && option.role === 'portfolio' && option.isAvailable,
															[styles.traderCheckbox]: showRoleColors && option.role === 'trader' && option.isAvailable,
															[styles.unavailableCheckbox]: !option.isAvailable
														})}
													/>
													{imageSrc && <img src={imageSrc} alt={option.label} className={styles.avatarImage} />}
													<Text variant="body2" color="neutral.50">
														{option.label}
													</Text>
												</label>
											)}
										</Box>
									)
								})
							) : (
								<Box className={styles.noResults}>
									<Text color="neutral.500">No results found</Text>
								</Box>
							)}
						</Box>
					</Box>
				</Popover.Content>
			</Popover.Portal>
		</Popover.Root>
	)
}
