import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import * as styles from './EarningMobile.css'
import { Box } from 'components/layout/box'
import { fetchEarnings } from 'api/right-side-panel'
import { Text } from 'components/typography/text'
import clsx from 'clsx'
import { InlineIcon } from 'components/icons/inline-icon'
import { PanelCalendarIcon } from 'assets/icons/icons'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { tokens } from 'style/theme.css'
import numeral from 'numeral'
import { Dropdown } from 'components/Dropdown'
import Loader from 'components/Loader/Loader'
import { useOktaAuth } from '@okta/okta-react'

dayjs.extend(customParseFormat)

const getColorBasedOnComparison = (value: number, estimate: number): string => {
	if (Number.isNaN(value)) {
		return 'inherit'
	} else if (value > estimate) {
		return tokens.colors['primary.500']
	} else if (value < estimate) {
		return tokens.colors['destructive.600']
	} else {
		return 'inherit'
	}
}

const getImportanceClassNames = (importance: number, index: number) => {
	return clsx(styles.importanceCircle, { [styles.importanceCircleActive]: index < importance })
}

const getUnitFormat = (value: string | number, unit?: string) => {
	if (value == '' || Number.isNaN(value)) {
		return '-'
	}
	if (unit == '%') {
		return `${value}${unit}`
	}
	if (unit == '$') {
		return `${unit}${numeral(value).format('0.00a').toUpperCase()}`
	}
	return `${numeral(value).format('0.00a').toUpperCase()} ${unit}`
}

const dropdownData = [
	{
		label: 'Today',
		value: 'today'
	},
	{
		label: 'This Week',
		value: 'this_week'
	},
	{
		label: 'Next Week',
		value: 'next_week'
	},
	// {
	// 	label: 'Next Month',
	// 	value: 'next_month'
	// }
	{
		label: 'Next 30 Days',
		value: 'next_30_days'
	}
]

export const EarningMobile = () => {
	const { oktaAuth } = useOktaAuth()
	const accessToken = oktaAuth.getAccessToken()
	const [dateFilter, setDateFilter] = useState(dropdownData[0])
	const {
		data: earningData,
		isLoading,
		isError
	} = useQuery({
		queryKey: ['calendar-earnings', dateFilter.value],
		queryFn: () => fetchEarnings(dateFilter.value, accessToken)
	})

	return (
		<Box display="flex" flexDirection="column" gap={4} overflow="auto" paddingTop={4}>
			<Box display="flex" flexDirection="column" gap={4}>
				<Box>
					<Dropdown data={dropdownData} handleFilter={setDateFilter} filter={dateFilter} />
				</Box>
				{isLoading && (
					<>
						<Loader text="Loading..." />
					</>
				)}
				{isError && (
					<Text variant="body1Bold" color="neutral.300">
						Error fetching data
					</Text>
				)}
				{!isLoading && !isError && earningData?.length === 0 && (
					<Text variant="body1Bold" color="neutral.300">
						No data available
					</Text>
				)}
				{earningData?.map((item, index) => (
					<Box key={index} className={styles.earningCard}>
						<Box display="flex" justifyContent="space-between" gap={1}>
							<Text variant="body1Bold" color={'secondary.400'}>
								{item.name}
							</Text>
							<Text variant="body1Bold" color={'primary.500'}>
								{item.ticker}
							</Text>
						</Box>

						<Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
							<Text variant="body1Bold" style={{ display: 'flex', alignItems: 'center' }}>
								<InlineIcon icon={PanelCalendarIcon} color="neutral.300" style={{ marginRight: '4px' }} />
								{dateFilter.value === 'today'
									? dayjs(`${item.date} ${item.time}`, 'YYYY-MM-DD HH:mm').format('h:mm A')
									: dayjs(item.date, 'YYYY-MM-DD').format('MMM D, YYYY')}
							</Text>
							<Tooltip content="Event importance">
								<div className={styles.importanceWrapper}>
									{[...Array(5)].map((_, index) => (
										<Box key={index} className={getImportanceClassNames(item.importance, index)}></Box>
									))}
								</div>
							</Tooltip>
						</Box>

						<Box display="flex" gap={4} flexWrap="wrap">
							{/* MCAP */}
							<Box display="flex" flexDirection="column">
								<Text variant="metadata" color="neutral.300">
									MCAP
								</Text>
								<Text variant="body2Bold" color="neutral.50">
									{getUnitFormat(item.mcap, '$')}
								</Text>
							</Box>
							{/* REV EST. */}
							<Box display="flex" flexDirection="column">
								<Text variant="metadata" color="neutral.300">
									REV EST.
								</Text>
								<Text variant="body2Bold" color="neutral.50">
									{getUnitFormat(parseFloat(item.revenue_est), '')}
								</Text>
							</Box>
							{/* REV  */}
							<Box display="flex" flexDirection="column">
								<Text variant="metadata" color="neutral.300">
									REV
								</Text>
								<Text variant="body2Bold" color="neutral.50">
									{(() => {
										const revEst = parseFloat(item.revenue_est)
										const rev = parseFloat(item.revenue)
										const color = getColorBasedOnComparison(rev, revEst)
										return <Box style={{ color }}>{getUnitFormat(rev, '')}</Box>
									})()}
								</Text>
							</Box>
							{/* EPS EST. */}
							<Box display="flex" flexDirection="column">
								<Text variant="metadata" color="neutral.300">
									EPS EST.
								</Text>
								<Text variant="body2Bold" color="neutral.50">
									{item.eps_est}
								</Text>
							</Box>
							{/* EPS  */}
							<Box display="flex" flexDirection="column">
								<Text variant="metadata" color="neutral.300">
									EPS
								</Text>
								<Text variant="body2Bold" color="neutral.50">
									{(() => {
										const epsEst = parseFloat(item.eps_est)
										const eps = parseFloat(item.eps)
										const color = getColorBasedOnComparison(eps, epsEst)
										return <Box style={{ color }}>{getUnitFormat(eps, '')}</Box>
									})()}
								</Text>
							</Box>
						</Box>
					</Box>
				))}
			</Box>
		</Box>
	)
}
