import React, { useState } from 'react'
import { Box } from 'components/layout/box'
import { Text } from 'components/typography/text'
import * as styles from './TradeNews.css'
import { TradeHistory } from 'api/mavbots-arena/fetchBotTrades'
import dayjs from 'dayjs'

interface Props {
	tradeHistory: TradeHistory[]
}

export const TradeNews = ({ tradeHistory }: Props) => {
	const [activeIndex, setActiveIndex] = useState(0)
	const [isAnimating, setIsAnimating] = useState(false)

	const handleSwap = () => {
		if (isAnimating || tradeHistory.length === 1) return // Prevent multiple clicks during animation or if only one item
		setIsAnimating(true)
	}

	const handleAnimationEnd = () => {
		setIsAnimating(false)
		setActiveIndex((activeIndex + 1) % tradeHistory.length)
	}

	return (
		<div
			className={styles.stackWrapper}
			onClick={tradeHistory.length > 1 ? handleSwap : undefined} // Disable click if only one news item
		>
			{tradeHistory.map((news, index) => {
				const relativeIndex = (index - activeIndex + tradeHistory.length) % tradeHistory.length

				// Only render the first three cards
				if (relativeIndex > 2) {
					return null
				}

				let cardClass = styles.messageBox

				// Apply animation classes only if there are multiple news items
				if (tradeHistory.length > 1) {
					if (relativeIndex === 0 && isAnimating) {
						cardClass += ` ${styles.swapAnimationClass}`
					}

					if (relativeIndex === 1 && isAnimating) {
						cardClass += ` ${styles.moveDownUpClass}`
					}
				}

				return (
					<Box
						key={news.id || index}
						className={cardClass}
						data-index={relativeIndex}
						onAnimationEnd={relativeIndex === 0 && isAnimating ? handleAnimationEnd : undefined}>
						{/* Header */}
						<Box display="flex" gap={1} justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
							<Text variant="metadata" color="neutral.500">
								{dayjs(news.datetime).isValid() ? dayjs(news.datetime).format('MM/DD/YY h:mmA') : news.datetime}
							</Text>
							<Text variant="metadataBold" color="neutral.100">
								<Text as="span" variant="metadata" color="neutral.500">
									Position Size:{' '}
								</Text>
								{news.size}
							</Text>
						</Box>
						{/* Body */}
						<Box>
							<Text variant="h3" color="neutral.100" style={{ fontWeight: '700' }} textAlign="center">
								{news.message}
							</Text>
						</Box>
					</Box>
				)
			})}
		</div>
	)
}
