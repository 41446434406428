import "src/style/theme.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/style/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA3WWWW7bMBCG33sKAUaABKhTcri7T5Il3iOLszmxXWdzUvTunaEDlEOyEmzDH0aj2X6Si/12+9L9/tZ18/lzOKzXO7HoxM/sv8T/52D2q6ecAtESqkUnC6QRVXYGoRAnObKIdoecuPQkZ37RASdh0c0gXTm9QBoF3Tm9RDr0Q1yGnF4V+V4vOmd9TlYYCXhmdIPIaoZuMTZjGbqjMHS0ccjpPdJxGKdJ5fShGfK6GfIj0l7RndMnpMGH2E853SA1I9053SLVWveGJbqjYgaIitn+IipBA8tiv+j2t5cXp0p975T93mn8xTkRZ7nRc9NIG2b00jSy3NNr08hzo7emUeBG71TodOX0gHSKEbuS0w9K3fcyDoqV+pPaokPPfUiUzswPFgqMCppZNxpvGUYJzYTQ3hmGFb3TBa051tSxHqbAuiBRSjM1gvbc2lKSgNO0ZNjR4ECQRYAoq5kLVkYmIhnSK5UdOP4S1xg1w6SucRmXk2P4iko1RDfyReWaSmWn2PMAV1+l6tkEyps2RsnNpO2V7hkm2UnjBR9jeZ+srVE8bhKe1EYBt14fx8RPvLCPRyyXPO6nhCdfBLhJeOwdD3Cb8HLQvFQkv2h6kHxOSH9jcE5wvE9tMJPhAT7TKgBaCDbI8oVa7JURvPOvx3Rs5E7ejnHHkafznkTSx57P4CEtc8F47vsjOQk68Hp/NjGQdjAXq9krQTazBNIONljwNQlIO3qUo2T1Bt2UPJg2ts0tBVA7c9y1GPPF3gEh7WwMoVw2282KMdSK2B3SR1r8muOuRosBXZLX5eqfKejCFNiuANdp+TsVHd0/0PPJWXpO4ccefTD7VRn9TXurv23t9XBH1FX4vj4DwANt5bXfdetsAI9Ea7+oMCgZygtqB6guVTKUli4Z6qp6FkVlS4aK8iV7oT6X8JXOCSV8ozLJKqB3wqpO80Dc1rX6+A//bHZB0SHu3Ndc1t1R0OyOUq3uqHSeq+NQpm6PstSe2tS1uqZQSporR6GYbIFQTK5AqCVfINRMKBBqIxiOVvUpVNG5ThUMx18aYn/+Aus/j0EtCwAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/MavbotsArena/Arena/LatestTrades/LatestTrades.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/MavbotsArena/Arena/LatestTrades/LatestTrades.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VUwZKiMBC9z1fkslV6yBSgoDKX+Y09RgiaBZJUEkVmi3/fTlARdONslXtQoDvd/d7r7nyWtC0UqalGMl58ZVWCfr8hFPxwD4SEJBkzbYqC98UHWDr4hffe8OKLvJELT+TSGxl7IhNv5MrrXXu9myc6PHD3zu7tvZczcAdqonaMg1eerF8KzQwTYFC0IoYd6ccQEbqInGlZEUi4Uyy3MfaJDa3BaijORHWouQYRCuW8RMJ7YtNfE0WvSrQYJyoq6liII1VFJRp8ShE5GGFtOlOiqvCW7smRCZUiXQth9jcuzYnEppU0RSfQhefECNVOSiPUsNzsUyex/cS1xtd62rQVhHPB6ZB4SxQ+B/WOAX6a4oZuS2bw9eiY0SRg2feMcTxBYZljvVeMl+dO34ny66ANK1rQlRvKTYoy+KdqKgCp2A7arw1Rxk0EyXPGd1Aqkqd+TK5wYgdnmBmy1dA047gbAZrFPTrFdnszKGgU4boQqk77V9vunzMMh+cPkUvBLFJMj4BY34myejwETpKcKZqdB1o01uz4YQZjpm8l8Kjjun/eD8JZTfp8l0spjDQCMg1RuXbDN9rHK8q1QwlDbWfvSNQMY705laUsHekC6mLNvmB6wmVfy5ka2msXB66r/briihZgW4+asbnZZ+zUv60SMVfmQf16fjfV3dtnTXNG7GBQyoF1jmY3U7dKoPTc1RtdDZNNebDiviW/LHo3pI1emvYZrTBaBxNewRnA5Zq8YOieUvdfZs85voRQspwSGqFdRP+lUUNaf47vE4ni5K9Enpbwwf2H4O4PyCb2p5EIAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var botCardGrid = 'p53zcl1';
export var botCardPortraitGrid = 'p53zcl2';
export var botCardWrapper = 'p53zcl4';
export var cardIndicator = 'p53zcl9';
export var latestTradesLayout = 'p53zcl0';
export var navigationButtons = 'p53zcl5';
export var scrollWrapper = 'p53zcl3';
export var swipeIndicator = 'p53zcl7';
export var swipeText = 'p53zcl8';